import {
  Box,
  CardBody,
  CardHeader,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import StyledAsterisk from "../library/ui_custom/styled_asterisk";
import Form from "../library/ui_primitives/form";
import Input from "../library/ui_primitives/input";
import {
  StyledButton,
  StyledCard,
  StyledFormControl,
  StyledPasswordViewButton,
} from "../signed_out/shared_components";

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

function ResetPasswordContent() {
  const navigate = useNavigate();
  const toast = useToast();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleShowPasswordClick = () => setShowPassword(!showPassword);
  const handleShowConfirmPasswordClick = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const [searchParams] = useSearchParams();
  const reset_password_token = searchParams.get("reset_password_token");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const mutation = useMutation(async (data: FormValues) => {
    try {
      const res = await axios.patch("/users/password", {
        password: data,
        reset_password_token: reset_password_token,
      });

      if (res.status === 200) {
        toast({
          title:
            "Your password has been successfully reset. Please log in with your new password.",
          status: "success",
        });

        navigate("/users/log_in");
      }
    } catch (error: any) {
      if (error.response?.data?.error?.key === "token_expired") {
        toast({
          title:
            "Your reset password token has expired. Please request another email.",
          status: "error",
        });
      } else {
        toast({
          title:
            "There was an error resetting your password. Please try again or contact help@claribee.com",
          status: "error",
        });
      }
    }
  });

  return (
    <StyledCard>
      <CardHeader textAlign="center">
        <Heading size="lg">Reset Your Password</Heading>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit((data) => mutation.mutateAsync(data))}>
          <StyledFormControl isRequired isInvalid={!!errors.password}>
            <FormLabel
              htmlFor="password"
              requiredIndicator={<StyledAsterisk />}
            >
              Password
            </FormLabel>{" "}
            <InputGroup size="md">
              <Input
                id="password"
                type={showPassword ? "text" : "password"}
                {...register("password", {
                  required: {
                    value: true,
                    message: "Error: Password is required",
                  },
                  minLength: {
                    value: 8,
                    message: "Error: Minimum length should be 8",
                  },
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()#])[A-Za-z\d@$!%*?&()#]{8,}$/,
                    message:
                      "Error: Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                  },
                })}
              />
              <InputRightElement width="4em">
                <StyledPasswordViewButton
                  variant="outline"
                  size="sm"
                  onClick={handleShowPasswordClick}
                >
                  {showPassword ? "Hide" : "Show"}
                </StyledPasswordViewButton>
              </InputRightElement>
            </InputGroup>
            <FormHelperText>Password must:</FormHelperText>
            <FormHelperText>- be at least 8 characters long</FormHelperText>
            <FormHelperText>
              - consist of uppercase letters and lowercase letters
            </FormHelperText>
            <FormHelperText>- consist of at least one number</FormHelperText>
            <FormHelperText>
              - consist of at least one special character (e.g. !@#$%&*())
            </FormHelperText>
            <FormErrorMessage>
              {errors.password && errors.password.message?.toString()}
            </FormErrorMessage>
          </StyledFormControl>
          <StyledFormControl
            isRequired
            isInvalid={!!errors.passwordConfirmation}
          >
            <Box marginTop="3em">
              <FormLabel
                htmlFor="passwordConfirmation"
                requiredIndicator={<StyledAsterisk />}
              >
                Confirm Password
              </FormLabel>{" "}
              <InputGroup size="md">
                <Input
                  id="passwordConfirmation"
                  type={showConfirmPassword ? "text" : "password"}
                  {...register("passwordConfirmation", {
                    required: {
                      value: true,
                      message: "Error: Password Confirmation is required",
                    },
                    validate: (val: string) => {
                      if (watch("password") != val) {
                        return "Error: Your passwords do not match";
                      }
                    },
                  })}
                />
                <InputRightElement width="4em">
                  <StyledPasswordViewButton
                    variant="outline"
                    size="sm"
                    onClick={handleShowConfirmPasswordClick}
                  >
                    {showConfirmPassword ? "Hide" : "Show"}
                  </StyledPasswordViewButton>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.passwordConfirmation &&
                  errors.passwordConfirmation.message?.toString()}
              </FormErrorMessage>
            </Box>
          </StyledFormControl>
          <StyledButton isLoading={isSubmitting} type="submit">
            Reset Password
          </StyledButton>
        </Form>
      </CardBody>
    </StyledCard>
  );
}

export default ResetPasswordContent;
