import {
  Box,
  Button,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { ChevronLeft, ChevronRight, Search } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";

import PrivateJournalEntryCard from "./private_journal_entry_card";
import { PrivateJournalEntryListing } from "./types";

export function PrivateJournalEntryListings({
  setDeletePrivateJournalEntryConfirmationId,
}: {
  setDeletePrivateJournalEntryConfirmationId: (id: number) => void;
}) {
  const [pagination, setPagination] = useState({
    pageIndex: 1, // initial page index
    pageSize: 10, // default page size
  });

  // This is the search text in the input field.
  const [displaySearchText, setDisplaySearchText] = useState<
    string | undefined
  >("");

  // This is the search text that will be used for the query, they are different
  // because we want to tie this with pagination using a useEffect.
  const [committedSearchText, setCommittedSearchText] = useState<
    string | undefined
  >("");

  const { data, refetch } = useQuery({
    queryKey: ["privateJournalEntries"],
    queryFn: (): Promise<{
      metadata: {
        page: number;
        size: number;
        totalPages: number;
      };
      privateJournalEntries: PrivateJournalEntryListing[];
      canUseAiFeatures: boolean;
    }> => {
      let url = `/api/user_app/private_journal_entries?page_index=${pagination.pageIndex}&page_size=${pagination.pageSize}`;
      if (committedSearchText) {
        url += `&search_text=${committedSearchText}`;
      }
      return axios.get(url).then((res) => res.data);
    },
  });

  const commitSearchText = () => {
    if (displaySearchText === committedSearchText) {
      return;
    }
    // Reset the pagination since we are doing a new search.
    setPagination({ ...pagination, pageIndex: 1 });
    setCommittedSearchText(displaySearchText);
  };

  useEffect(() => {
    refetch();
  }, [pagination, committedSearchText]);

  return (
    <Box
      backgroundColor="core.apple"
      paddingLeft="1em"
      paddingRight="1em"
      paddingBottom="0.5em"
      paddingTop="1em"
      borderRadius="0.5em"
    >
      <Box marginBottom="2em">
        <Heading as="h2" size="md">
          Saved Notes
        </Heading>
      </Box>
      <Skeleton isLoaded={data !== undefined}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap="1em"
          marginBottom="1em"
        >
          <InputGroup flex="2">
            <InputLeftElement pointerEvents="none">
              <Icon as={Search} color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search"
              value={displaySearchText}
              aria-label="Search"
              onChange={(e) => {
                setDisplaySearchText(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  commitSearchText();
                }
              }}
            />
            <InputRightElement width="">
              <Button
                onClick={commitSearchText}
                isDisabled={displaySearchText === committedSearchText}
              >
                Search
              </Button>
            </InputRightElement>
          </InputGroup>

          <Box
            flex="1"
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            gap="0.5em"
          >
            <IconButton
              aria-label="Search database"
              icon={<ChevronLeft />}
              onClick={() => {
                setPagination({
                  ...pagination,
                  pageIndex: Math.max(pagination.pageIndex - 1, 1),
                });
              }}
              isDisabled={pagination.pageIndex <= 1}
            />
            <Text>
              Page {data?.metadata.page} of {data?.metadata.totalPages}
            </Text>

            <IconButton
              aria-label="Next page"
              icon={<ChevronRight />}
              onClick={() => {
                setPagination({
                  ...pagination,
                  pageIndex: Math.min(
                    pagination.pageIndex + 1,
                    data!.metadata.totalPages
                  ),
                });
              }}
              isDisabled={pagination.pageIndex === data?.metadata.totalPages}
            />
          </Box>
        </Box>
        {data?.privateJournalEntries?.map((entry) => (
          <PrivateJournalEntryCard
            key={entry.id}
            id={entry.id}
            title={entry.title}
            createdAt={entry.createdAt}
            truncatedContent={entry.truncatedContent}
            onDeleteClicked={(id) => {
              setDeletePrivateJournalEntryConfirmationId(id);
            }}
            summaryText={entry.summaryText}
          />
        ))}
      </Skeleton>
    </Box>
  );
}
